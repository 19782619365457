<template>
  <div class="newsList">
    <div class="banner">
      <Head />
      <img src="https://thcdn.gggamedownload.com/source/mobileAssets/ziye/z53.jpg" alt="">
    </div>
    <div class="body">
      <van-tabs v-model="active" animated swipeable sticky>
        <van-tab v-for="item in titleList" :key="item.id" :name="item.type" :title="item.title">
          <List :tag="item.type" />
        </van-tab>
      </van-tabs>
    </div>
    <Footer />
  </div>
</template>

<script>
import Head from '../components/Head'
import Footer from '@/components/MobileFloor'
import List from './List'
import { mapMutations } from 'vuex'

export default {
  name: 'NewsList',
  components: {
    Head,
    Footer,
    List
  },
  filters: {
    fileter_monteh(val) {
      if (val) {
        const str = val.substring(0, 10)
        const index = str.indexOf('-')
        const newVal = str.substring(index + 1)
        return newVal
      }
      return val
    },
    fileter_year(val) {
      if (val) {
        const str = val.substring(0, 10)
        const index = str.indexOf('-')
        const newVal = str.substring(0, index)
        return newVal
      }
      return val
    }
  },
  data() {
    return {
      active: '',
      titleList: [
        {
          id: 1,
          title: '最新',
          type: null
        },
        {
          id: 2,
          title: '新闻',
          type: 'news'

        },
        {
          id: 3,
          title: '公告',
          type: 'announcement'

        },
        {
          id: 4,
          title: '活动',
          type: 'gameWalkthrough'

        },
        {
          id: 5,
          title: '见闻纪',
          type: 'gameStrategy'
        }
      ],
      list: [],
      loading: false,
      finished: false,
      queryForm: {
        channel: 'th.gg.com',
        pageSize: 10,
        currentPage: 1,
        tag: ''
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    })
  },
  methods: {
    ...mapMutations(['ADD_keepAliveKey', 'DEL_ADD_keepAliveKey'])

  },
  beforeRouteLeave(to, from, next) {
    if (to.path === '/newsDetails') {
      this.ADD_keepAliveKey('NewsList')
    } else {
      this.DEL_ADD_keepAliveKey('NewsList')
    }
    next()
  }
}
</script>

<style lang="scss" scoped>
::v-deep {

  .van-tabs__line {
    background-color: #4a5f6d;
  }
}
.newsList {
  padding-top: 59px;

   .banner {
    img {
      width: 100%;
    }
  }
  .body {
    width: 100%;
    height: 100%;
    min-height: 700px;
  }
}
</style>
